import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";

// Components
import NavBar from "./components/NavBar";
import Home from "./components/Home";
import Story from "./components/Story";
import Contact from "./components/Contact";
import Locations from "./components/Locations";
import Footer from "./components/Footer";
import ScrollToTopButton from "./components/ScrollTop";

// CSS
import "./static/Reset.css";
import "./static/App.css";

function App() {
  return (
    <Router>
      <div>
        <NavBar />
        <Routes>
          <Route path="/home" exact element={<Home />} />
          <Route path="/" exact element={<Home />} />
          <Route path="/Locations" element={<Locations />} />
          <Route path="/Story" element={<Story />} />
          <Route path="/Contact" element={<Contact />} />
        </Routes>
        <ScrollToTopButton />
        <Footer />
      </div>
    </Router>
  );
}

export default App;

/*

<Routes>
          <Route path="/home" exact element={<Home />} />
          <Route path="/" exact element={<Home />} />
          <Route path="/Locations" element={<Locations />} />
          <Route path="/Story" element={<Story />} />
          <Route path="/Contact" element={<Contact />} />
        </Routes>
        <ScrollToTopButton />
        <Footer />
        */
