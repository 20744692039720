import React from "react";
//import './MyComponent.css';

import "../static/Story.css";

const Story = () => {
  return (
    <div className="our-story my-component">
      <h2 className="component_title">Our Story</h2>

      <div className="origin-story">
        <h3>Origin</h3>
        <p>
          The story of Honey Haven began with a family's journey to find a
          healthier alternative to sugar. With a history of diabetes in the
          family, both grandpa and dad couldn't consume sugar without
          experiencing spikes in their sugar levels. Turning to nature for a
          solution, they discovered the natural sweetness of honey, which had no
          adverse effects on their health.
        </p>
        <p>
          Witnessing the transformative power of pure honey firsthand sparked an
          idea. Realizing the untapped potential and the growing need for pure,
          unadulterated honey in the market, he embarked on a mission to share
          this golden nectar with others. Thus, Honey Haven was born, driven by
          a passion for offering the best quality, family-owned, local organic
          honey to those seeking a wholesome and natural sweetener.
        </p>
      </div>

      <div className="background">
        <h3>Pure Honey, Pure Bliss</h3>
        <p>
          With over 12 years of experience in the honey industry, Honey Haven
          has established itself as a trusted name known for its commitment to
          quality and authenticity. Over the years, we have honed our craft,
          perfected our sourcing methods, and built strong relationships with
          local beekeepers to ensure that we offer only the finest, pure honey
          to our customers.
        </p>
        <p>
          Our deep-rooted knowledge and expertise in beekeeping practices have
          allowed us to maintain the highest standards of quality, making us a
          go-to destination for those seeking premium, family-owned, local
          organic honey products. With a decade-long legacy of excellence, we
          continue to uphold our dedication to providing a taste of nature’s
          pure sweetness through every jar of honey we produce.
        </p>
      </div>
    </div>
  );
};

export default Story;
