import React from "react";
//import './MyComponent.css';

import "../static/Contact.css";

const Contact = () => {
  return (
    <div className="contact-page my-component">
      <h2 className="component_title">Contact Us</h2>

      <div className="contact-info">
        <p>
          <strong>Phone Number:</strong> 971-864-9547
        </p>
        <p>
          <strong>Email Address:</strong>{" "}
          <a href="mailto:info@shophoneyhaven.com">info@shophoneyhaven.com</a>
        </p>
        <p>
          <strong>Address:</strong>
          <br />
          2355 State St
          <br />
          STE 101
          <br />
          Salem, OR 97301
        </p>
      </div>
    </div>
  );
};

export default Contact;
