import React from "react";
//import './MyComponent.css';

import HH from "../images/HH_2.png";
import "../static/Home.css";

const Home = () => {
  return (
    <div>
      <div className="intro-div">
        <div>
          <h2>A Gift from Nature to You</h2>
          <p>
            At Honey Haven, we're committed to sweetness in every drop.
            Experience nature's liquid gold, just as it was meant to be.
          </p>
          <div className="intro-div-link">
            <a href="/story">Our Story </a>
          </div>
        </div>
        <div className="intro-div-img">
          <img src={HH} />
        </div>
      </div>
      <div className="black-background">
        <div className="our-mission">
          <h3>Our Mission</h3>
          <p>
            At Honey Haven, we're on a mission to deliver the purest, most
            flavorful honey straight from our family-owned organic apiary. With
            every drop, we're committed to crafting exceptional honey products
            that not only delight your palate but also support sustainable
            beekeeping practices. Join us in nurturing a profound connection to
            nature and savor the sweet essence of our commitment to quality.
          </p>
        </div>
        <div className="why-love-us">
          <h3>Why Our Friends Love Us</h3>
          <p>
            <strong>Quality You Can Taste:</strong> Our honey is raw and
            unfiltered, capturing the essence of nature.
          </p>
          <p>
            <strong>Sustainable Practices:</strong> We prioritize eco-friendly
            methods and support local beekeepers.
          </p>
          <p>
            <strong>Community Focused:</strong> We're passionate about giving
            back to the community that supports us.
          </p>
          <p>
            <strong>Personalized Service:</strong> We treat everyone like
            family, ensuring a delightful experience.
          </p>
          <p>
            <strong>Delicious Variety:</strong> From wildflower to infused
            flavors, there's something for everyone to enjoy!
          </p>
        </div>
      </div>
    </div>
  );
};

export default Home;
