import React, { useState, useEffect } from "react";
import arrowUp from "../images/arrow_up.png"; // Adjust the path as necessary
import "../static/ScrollTop.css";

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 20) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    isVisible && (
      <button
        onClick={scrollToTop}
        id="scrollToTopBtn"
        title="Go to top"
        style={{
          display: isVisible ? "block" : "none",
        }}
      >
        <img src={arrowUp} alt="Scroll to top" />
      </button>
    )
  );
};

export default ScrollToTopButton;
