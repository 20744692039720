import React from "react";
//import './MyComponent.css';

const Location = () => {
  return (
    <div className="my-component">
      <h2 className="component_title">Our Locations!</h2>
      <iframe
        src="https://www.google.com/maps/d/u/0/embed?mid=1jhWqyU0oDgvtaZSP0hvY1hj1RDXX9KY&ehbc=2E312F&noprof=1"
        width="640"
        height="480"
        style={{ maxWidth: "100%", maxHeight: "100%", border: "none" }}
      ></iframe>
    </div>
  );
};

export default Location;
